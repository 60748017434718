.logo-text {
  color: #fff;
  /* margin-top: 10px; */
  vertical-align: middle;
}
.shadow {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}
.app-header {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}
.app-sidebar {
  background: #181c32 !important;
}
.app-sidebar .menu > .menu-item .menu-link.active {
  background-color: #3699ff !important;
}
body.modal-open {
  overflow: hidden;
}
.form-control.form-control-solid,
.form-select.form-select-solid {
  background-color: var(--bs-gray-200);
  border-color: var(--bs-gray-200);
  color: var(--bs-gray-700);
  transition: color 0.2s ease;
}
.c-p {
  cursor: pointer;
}
.help-text {
  color: #a1a5b7;
}
.register {
  .card-header {
    min-height: 60px !important;
    padding: 1.25rem 2.25rem !important;
  }
  .action-take {
    align-self: center;
  }
}
.print-card {
  width: 850px;
}
.flex-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.print-header {
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.01em;
}
.print-label {
  width: 48%;
  display: inline-block;
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: left;
}
.flight-image {
  margin-left: 48%;
}
.print-value {
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: left;
}
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.max-w-200px {
  max-width: 200px;
}
.custom-loader .http-loader {
  display: block !important;
}

.http-loader {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  display: none;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.http-loader:after {
  content: "";
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: #548235;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}
